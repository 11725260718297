<template>
  <div>
    <div
      class="container-fluid"
      style="margin-top: 71px; position: relative"
      v-show="showBlock === 'planningResult'"
    >
      <Plan ref="savePlanModal" @saveResult="saveResult" />
      <Toast
        v-if="toastData.show"
        :type="toastData.type"
        :message="toastData.message"
        :show="toastData.show"
        :time="10000"
        @clearToast="clearToast"
      />
      <div>
        <ul class="breadcrumb ProximaNovaRegular">
          <li>
            <router-link to="/solutions/ul/platform/allstats">
              <span>Model</span>
            </router-link>
          </li>
          <!-- <li>
            <router-link to="/solutions/digital-poem/stats">
              <span>Sale Statistics</span>
            </router-link>
          </li> -->
          <li>
            <router-link to="/solutions/ul/platform/insights">
              <span>Future Planning</span>
            </router-link>
          </li>
           <li>
            <router-link to="/solutions/ul/scenario-planning/planning-results">
              <span>Planning Results</span>
            </router-link>
          </li>
        <li>
            <router-link to="/solutions/ul/scenario-planning">
              <span>Scenario Planning</span>
            </router-link>
          </li>
          <li class="ProximaNovaBold">Optimized Planning Result</li>
        </ul>
      </div>
      <div
        class="d-flex mt-4"
        :class="[expand ? 'justify-content-end' : 'justify-content-between']"
      >
        <div class="w-100 d-flex" style="justify-content: space-between">
          <div>

                <div   class="card-header font-large ProximaNovaBold content-center"
        style="
          justify-content: space-between;
          background: transparent;
          border: 0px !important;
        ">
           <p>Brand - {{brand}}</p>
      <p class="ml-5">KPI - {{kpi}}</p>
            <!-- <div
              class="d-none d-md-block card arrow-card"
              :class="[expand ? 'arrow-card-collapse' : '']"
              @click="toggleExpand"
            >
              <i v-if="!expand" class="fas fa-arrow-left arrow"></i>
              <i v-else class="fas fa-arrow-right arrow-white"></i>
            </div> -->
          </div>
            <!-- <div
              class="d-none d-md-block card arrow-card"
              :class="[expand ? 'arrow-card-collapse' : '']"
              @click="toggleExpand"
            >
              <i v-if="!expand" class="fas fa-arrow-left arrow"></i>
              <i v-else class="fas fa-arrow-right arrow-white"></i>
            </div> -->
          </div>
          <div>
            <w-button
              class="mx-2 btn-effect"
              :isDisable="true"  
              @buttonClicked="$refs.savePlanModal.open({ action: 'saveResult' })"
              :buttonText="'Save Plan'"
              style="width: auto"
            >
            </w-button>
            <!--<w-button
              class="mx-2 btn-effect"
              @buttonClicked="showAgile"
              :buttonText="'Agile Planning'"
              style="width: auto;"
            >
            </w-button>-->
            <!-- <w-button
              class="btn-effect-outline"
              @buttonClicked="gotoScenarioPlan"
              :buttonLoader="'none'"
              :buttonText="'Scenerio Planning'"
              style="width: auto"
            >
            </w-button> -->
          </div>
        </div>
      </div>

      <b-row>
        <b-col cols="12" md="4" :class="[expand ? 'd-none' : '']">
          <div class="card d-none">
            <div class="card-body p-0">
              <div class="card-title">Prediction</div>
              <div class="mt-5">
                <w-tab
                  :tabName="activePredictionTab"
                  :tabs="predictionTab"
                  @activeTab="changePredictionTab($event)"
                >
                </w-tab>
              </div>
              <b-container fluid class="slide-parent">
                <SalesGrowth
                  v-show="activePredictionTab === 'Budget Based'"
                  :sliderValue="sliderRevenueValue"
                  :data="revenueData"
                  :bigRangeSize="true"
                  :step="5"
                  :handleColor="'#536dfe'"
                  @sliderChangeHandle="handleRevenueSlider"
                  :sliderName="'Budget Growth'"
                  :specialChar="''"
                />
                <SalesGrowth
                  v-show="activePredictionTab === kpiMarket + ' Based'"
                  :sliderValue="sliderKPIValue"
                  :data="kpiData"
                  :bigRangeSize="true"
                  :step="5"
                  :handleColor="'#536dfe'"
                  @sliderChangeHandle="handleKPISlider"
                  :sliderName="this.kpiMarket + ' Growth'"
                  :specialChar="''"
                />
                <!--<SalesGrowth
                  v-if="activePredictionTab === 'Budget Based'"
                  :sliderValue="sliderBudgetValue"
                  :data="budgetData"
                  :bigRangeSize="true"
                  @sliderChangeHandle="handleBudgetSlider"
                />-->
                <div
                  class="w-100 py-4"
                  style="text-align: center; font-size: medium; color: red"
                >
                  <span>{{ errorMessage }}</span>
                </div>
              </b-container>
            </div>
          </div>
          <div class="card mt-5 mb-5 mb-md-0 d-none">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-12">
                  <details class="">
                    <Summary class="Additional-details">Additional Details</Summary>
                    <b-container fluid>
                      <div
                        v-for="(detail, index) in additionalDetails"
                        :key="index"
                        class="row additonal-detail py-5 px-4"
                        :class="[!detail.select ? 'border-top' : 'detail-bg']"
                      >
                        <b-col cols="10">
                          <div class="details-heading">{{ detail.name }}</div>
                          <div v-if="detail.select">
                            <label>Product</label>
                            <b-form-select
                              v-model="detail.product"
                              :options="detail.dropdownList"
                            ></b-form-select>
                            <label>Date</label>
                            <div>
                              <input
                                type="date"
                                :value="detail.date"
                                class="date-input"
                              />
                            </div>
                            <label>Value in numbers</label>
                            <b-form-input
                              v-model="detail.value"
                              type="number"
                            ></b-form-input>
                          </div>
                        </b-col>
                        <b-col cols="2">
                          <input
                            :name="`checkbox-${index}`"
                            type="checkbox"
                            class="form-check-input"
                            :value="detail.select"
                            @input="updateCheckbox(index, $event.target.checked)"
                          />
                        </b-col>
                      </div>
                    </b-container>
                  </details>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" :md="expand ? 12 : 8">
          <div class="card mb-4 mt-5">
            <div class="card-body p-0">
              <div class="card-title">Prediction</div>
              <b-container fluid class="wrapper-statistics">
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                    :md="expand ? 2 : 4"
                    v-for="(data, index) in predictionData"
                    :key="index"
                    class="item"
                  >
                    <div
                      class="item-img"
                      :class="[
                        index === 0
                          ? 'blue-pastel'
                          : index === 1
                          ? 'green-pastel'
                          : index === 2
                          ? 'pink-pastel'
                          : index === 3
                          ? 'red-pastel'
                          : index === 4
                          ? 'yellow-pastel'
                          : index === 5
                          ? 'purple-pastel'
                          : 'blue-pastel',
                      ]"
                    >
                      <!-- <i class="fas fa-chart-pie" style="font-size: 24px"></i> -->
                      <img
                        :src="require(`@/assets/Icons/${data.image}.svg`)"
                        :alt="data.name"
                        height="25px"
                      />
                    </div>

                    <div class="item-text" v-if="data.name === 'Change'">
                      <div class="item-text__title">{{ data.name }}</div>
                      <div class="item-text__subtitle">{{ data.value }}</div>
                    </div>
                    <div class="item-text" v-else>
                      <div class="item-text__title">{{ data.name }}</div>
                      <div class="item-text__subtitle">
                        {{ orgnizeNumber(data.value) }}
                        {{data.symbol}}
                      </div>
                    </div>

                    <div
                      v-if="
                        (!expand && (index + 1) % 3 !== 0) ||
                        (expand && (index + 1) % 6 !== 0)
                      "
                      class="item-right-line"
                    ></div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>
          <!--<div class="card mb-4">
            <div class="card-body p-3">
              <ColumnChart :chartData="columnChartData" />
            </div>
          </div>
          <div class="card mb-4">
            <div class="card-body pt-2">
              <Digital
                :data="digitalExpandData"
                :stripData="digitalData"
                subtitle="Recommended Digital Mix"
                @getDataBreakdown="getDigitalBreakdown"
                :showBreakdownIcon="false"
                total=""
              />
            </div>
          </div>-->

          <div
            class="card mb-4 w-100"
            v-for="(optimized, index) in optimizedData"
            :key="index"
          >
            <div class="card-body pt-2 w-100" style="overflow: auto">
              <Content
                :stripData="optimized.stripData"
                :subtitle="'Recommended Digital Media Mix'"
                total=""
                :title="'Digital Media'"
                @chartClick="getMediaBreakDown($event)"
                :barData="optimized.expandData"
                :levelTwoBarData="optimized.expandLevelTwoData"
                :showBreakdownIcon="true"
                :breakeDownShow="optimized.breakDown"
                class="m-0"
                ref="contentBlock"
                :showLegend="true"
                @getDataBreakdown="getExpandData($event, index)"
              />
            </div>
          </div>
          <!--<div class="card">
            <div class="card-body p-3">
              <LineChart :chartData="lineChartData" />
            </div>
          </div>-->
        </b-col>
      </b-row>
      <!-- <page-loader v-if="countApi == countAllApi"></page-loader> -->
    </div>
  </div>
</template>

<script>
// import PageLoader from "@/widgets/PageLoader.vue";
import Tab from "@/components/Solutions/Tab.vue";
import SalesGrowth from "@/components/Solutions/AISalesGrowth.vue";
// import Digital from "@/components/Solutions/Digital.vue";
import Content from "@/components/Solutions/ActualDataContent.vue";
// import LineChart from "@/components/Chart/LineChart.vue";
// import ColumnChart from "@/components/Chart/ColumnChart.vue";
import Plan from "@/components/Modal/SaveModelModal.vue";
import Toast from "@/components/Toast/Toast.vue";
import Button from "@/components/Profile/Button.vue";
// import { DigitalPoemServices } from "@/services/DigitalPoemServices.js";
// const digitalPoemService = new DigitalPoemServices();
// import AgilePlanningResult from "./AgilePlanningResults.vue";
import { AIDigitalPoem } from "../../../services/AIDigitalPoemServices";
const aiDigitalPoem = new AIDigitalPoem();
import "@/mixins/SortNumber.js";
import moment from "moment";
import { abbreviateNumber } from "js-abbreviation-number";

export default {
  name: "PlanningResultsUL",
  components: {
    "w-tab": Tab,
    "w-button": Button,
    SalesGrowth,
    // Digital,
    Content,
    // LineChart,
    // ColumnChart,
    Plan,
    Toast,
    // PageLoader,
    // "w-agile-planning-result": AgilePlanningResult,
  },
  data() {
    return {
      brand: localStorage.getItem("selectedBrandKPI"),
      kpi: localStorage.getItem("selectedMediaKPI"),
      budgetDataOptimized: "",
      isLoading: false,
      errorMessage: "",
      showBlock: "planningResult",
      countApi: 0,
      market: "",
      expand: true,
      kpiMarket: "",
      saveOptimizedResult: {
        runId: "",
        planName: "",
        date: moment(new Date()).format("YYYY-MM-DD"),
        kpiConfig: "",
        values: {
          growth: "",
          kpiSelection: {},
          mediaData: [],
          optimised_output: {},
          target: "",
        },
      },
      optimizedData: [
        {
          title: "",
          stripData: [],
          expandData: {
            data: [
              {
                name: "Growth",
                data: [],
              },
            ],
            xAxis: {
              categories: [],
              crosshair: true,
            },
            yAxis: {
              min: 0,
              title: {
                text: "Growth",
              },
            },
            plotOptions: {
              column: {
                pointPadding: 0.4,
                borderWidth: 0,
              },
              series: {
                borderRadiusTopLeft: 25,
                borderRadiusTopRight: 25,
              },
            },
            subtitle: {},
            legend: {},
            color: ["#40c4ff", "#536dfe"],
            tooltip: {
              headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
              pointFormat:
                '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
              footerFormat: "</table>",
              shared: true,
              useHTML: true,
              shadow: false,
              style: {
                padding: 0,
              },
            },
          },
          allExpandData: [],
          breakDown: false,
        },
      ],
      // predictionTab: [
      //   {
      //     category: "Budget Based",
      //   },
      //   {
      //     category: this.kpiMarket+" Based",
      //   },
      // ],
      digitalExpandData: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30],
          },
          {
            name: "RIO",
            data: [50, 30, 50, 50],
          },
        ],
        xAxis: {
          categories: ["Instagram", "Facebook", "Youtube", "Twitter"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        subtitle: {},
        legend: {},
        color: ["#40c4ff", "#536dfe"],
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      activePredictionTab: "Budget Based",
      sliderRevenueValue: 16,
      sliderKPIValue: 0,
      revenueData: [{ name: "Expected Revenue", value: 621 }],
      kpiData: [{ name: "Expected Budget", value: 621 }],
      sliderBudgetValue: 46,
      budgetData: [{ name: "Expected Budget", value: 621 }],
      predictionData: [],
      lineChartData: {
        colors: ["#050505", "#18ffff"],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function () {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x).options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
              </b>`;
            } else {
              return value;
            }
          },
        },
        series: [
          {
            data: [1, 2, 3, 3, 2, 1, 4, 5, 3, 7, 9, 10],
            marker: {
              symbol: "circle",
              radius: 10,
              // status: {
              //   hover: {
              //     enabled: true,
              //   },
              // },
            },
            type: "spline",
            name: "Actual",
          },
          {
            data: [3, 2, 1, 2, 1, 4, 1, 2, 3, 3, 9, 10],
            marker: {
              symbol: "circle",
              radius: 10,
            },
            type: "spline",
            name: "Predicted",
          },
        ],
        title: {
          text: "Media - Previous vs Predicted Budget",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {
          text: "Recommended Budget Mix",
          align: "left",
          y: 60,
          style: {
            color: "#8394b4",
            fontSize: "20px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        legend: {
          align: "left",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        yAxis: {
          title: {
            text: "sales growth %",
            style: {
              color: "#8394b4",
            },
            y: 12,
          },
        },
        xAxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "August",
            "Sept",
            "October",
            "November",
            "December",
          ],
        },
      },
      additionalDetails: [
        {
          name: "Product",
          product: "",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          date: "",
          value: "",
        },
        {
          name: "Distribution",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          product: "",
          date: "",
          value: "",
        },
        {
          name: "Consumer Promo",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          product: "",
          date: "",
          value: "",
        },
        {
          name: "Advertising",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          product: "",
          date: "",
          value: "",
        },
      ],
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      digitalThirdLevelData: [],
      contentThirdLevelData: [],
      contentThirdLevelAgileData: [],
      digitalThirdLevelAgileData: [],
      levelOneData: [],
      colors: [
        "#f77f00",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
        "#08a0e9",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
      ],
      digitalData: [],
    };
  },
  mounted() {
    const optimizedBudgetData = this.$router.history.current.params.optimizedBudgetData;
    this.budgetDataOptimized = optimizedBudgetData
    if (sessionStorage.budgetOptimizer === "") {
      this.isLoading = localStorage.true;
    }
  },
  // watch: {
  //   name(newName) {
  //     localStorage.name = newName;
  //   }
  // },
  methods: {
    getMediaBreakDown(e) {
      const res = this.budgetDataOptimized
    
      const selectedChannel = res[0].redistribution[0].levelThreeList.filter((item) =>
        item.name.includes(e.text)
      );

      this.optimizedData[0].expandLevelTwoData.data[0].data = [];
      this.optimizedData[0].expandLevelTwoData.data[1].data = [];
      this.optimizedData[0].expandLevelTwoData.xAxis.categories = [];

      for (var i = 0; i < selectedChannel[0].value.length; i++) {
        this.optimizedData[0].expandLevelTwoData.data[0].data.push(
          selectedChannel[0].value[i].previous
        );
        this.optimizedData[0].expandLevelTwoData.data[1].data.push(
          selectedChannel[0].value[i].optimised
        );

        this.optimizedData[0].expandLevelTwoData.xAxis.categories.push(
          selectedChannel[0].value[i].name
        );
      }
    },
    // saveResult(e) {
    //   this.saveOptimizedResult.planName = e;
    //   this.saveOptimizedResult.kpiConfig = sessionStorage.getItem("kpiConfig");
    //   aiDigitalPoem
    //     .saveOptimizationResult(this.saveOptimizedResult)
    //     .then((res) => {
    //       if (res === "Duplicate data entry") {
    //         this.toastData.show = true;
    //         this.toastData.type = "failed";
    //         this.toastData.message =
    //           "This result is allready saved in our system";
    //       } else if (res === "Plan Name already exists") {
    //         this.toastData.show = true;
    //         this.toastData.type = "error";
    //         this.toastData.message = "This name is allready exists";
    //       } else {
    //         this.updatePlan();
    //       }
    //     });
    // },
    showAgile() {
      this.showBlock = "agile";
    },
    handleBudgetSlider(e) {
      this.sliderBudgetValue = e;
    },
    handleRevenueSlider(e) {
      if (e > 41) {
        this.errorMessage = "Value must be in between 0-40";
        return;
      }
      this.errorMessage = "";
      this.sliderRevenueValue = e;
      this.countApi = 1;
      sessionStorage.setItem("actualGrowth", e);
      sessionStorage.setItem("edited", "notEdited");
      this.getAIPlanningResult();
    },
    handleKPISlider(e) {
      if (e > 41) {
        this.errorMessage = "Value must be in between 0-40";
        return;
      }
      this.errorMessage = "";
      this.sliderKPIValue = e;
      this.countApi = 1;
      sessionStorage.setItem("actualGrowth", e);
      sessionStorage.setItem("edited", "notEdited");
      this.getAIPlanningResult();
    },
    changePredictionTab(tabName) {
      if (tabName === this.activePredictionTab) {
        return;
      }

      this.activePredictionTab = tabName;
      sessionStorage.setItem("edited", "notEdited");
      if (tabName === "Budget Based") {
        sessionStorage.setItem("targetVariable", "budget");
      } else if (tabName === this.kpiMarket + " Based") {
        sessionStorage.setItem("targetVariable", "KPI");
      }
      this.getAIPlanningResult();
    },
    toggleExpand() {
      this.expand = !this.expand;
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    },
    updateCheckbox(index, val) {
      this.additionalDetails[index].select = val;
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    updatePlan() {
      this.toastData = {
        show: true,
        type: "success",
        message: "Yay, our system has saved your plan successfully",
      };
    },
    gotoScenarioPlan() {
      if (this.optimizedData[0].stripData.length === 0) {
        return;
      }
      this.$router.push({
        name: "ScenarioPlanning UL",
        params: {scenarioPlanningData: this.budgetDataOptimized}
        });
    },
    // getDigitalData() {
    //   var color = [
    //     "#6a1b9a",
    //     "#8e24aa",
    //     "#ab47bc",
    //     "#ba68c8",
    //     "#ce93d8",
    //     "#e1bee7",
    //     "#e1bee7",
    //     "#e1bee7",
    //   ];
    //   var growth = 0;
    //   if (this.activePredictionTab === "Budget Based") {
    //     growth = this.sliderRevenueValue;
    //   } else {
    //     growth = this.sliderKPIValue;
    //   }
    //   digitalPoemService
    //     .getPredictedData({
    //       fileName: "digitalleveltwo",
    //       growthVal: growth.toString(),
    //       market: this.market,
    //     })
    //     .then((res) => {
    //       this.digitalData = [];
    //       this.digitalThirdLevelAgileData = [];
    //       for (var i = 0; i < res.length; i++) {
    //         this.digitalData.push({
    //           label: res[i].name,
    //           value: res[i].value,
    //           backgroundColor: color[i],
    //         });
    //         this.getDigitalThirdLevelData({
    //           label: res[i].name,
    //           value: res[i].value,
    //         });
    //       }
    //       this.getDigitalAgile(res);
    //       this.countApi++;
    //     })
    //     .catch(() => {
    //       this.countApi++;
    //     });
    // },
    getAIPlanningResult() {
      var color = [
        "#6a1b9a",
        "#8e24aa",
        "#ab47bc",
        "#ba68c8",
        "#ce93d8",
        "#e1bee7",
        "#e1bee7",
        "#e1bee7",
        "#e1bee7",
        "#e1bee7",
        "#e1bee7",
        "#e1bee7",
        "#e1bee7",
        "#e1bee7",
      ];
      // var growth = 0;
      // if (this.activePredictionTab === "Budget Based") {
      //   growth = this.sliderRevenueValue;
      // } else {
      //   growth = this.sliderKPIValue;
      // }
      aiDigitalPoem
        .getExpectedRevenue("202112-2310-0322-7f7a-4_ykbfqzxtqdiclezqnauk", "5", "budget")
        .then(() => {
          // var res1 = {
          //   mediaData: [
          //     {
          //       levelOne: [
          //         {
          //           name: "digital",
          //           previous: 790832.26,
          //           optimised: 1041605.8,
          //         },
          //         {
          //           name: "digital_performance",
          //           previous: 382147.61,
          //           optimised: 584618.47,
          //         },
          //         {
          //           name: "tv",
          //           previous: 1150908.7,
          //           optimised: 3197417.08,
          //         },
          //       ],
          //       levelTwo: [
          //         {
          //           name: "digital",
          //           value: [
          //             {
          //               name: "digital - audio",
          //               previous: 0.0,
          //               optimised: 391.77,
          //             },
          //             {
          //               name: "digital - display",
          //               previous: 132481.74,
          //               optimised: 149837.65,
          //             },
          //             {
          //               name: "digital - fep",
          //               previous: 22952.48,
          //               optimised: 25617.4,
          //             },
          //             {
          //               name: "digital - social",
          //               previous: 153204.87,
          //               optimised: 223791.26,
          //             },
          //             {
          //               name: "digital - video",
          //               previous: 482193.17,
          //               optimised: 641967.72,
          //             },
          //           ],
          //         },
          //         {
          //           name: "digital_performance",
          //           value: [
          //             {
          //               name: "commerce (display)",
          //               previous: 97042.95,
          //               optimised: 218200.82,
          //             },
          //             {
          //               name: "commerce (search)",
          //               previous: 275529.76,
          //               optimised: 305942.03,
          //             },
          //             {
          //               name: "digital search (brand.com)",
          //               previous: 9574.89,
          //               optimised: 60475.63,
          //             },
          //           ],
          //         },
          //         {
          //           name: "tv",
          //           value: [
          //             {
          //               name: "a&e television networks llc",
          //               previous: 162911.56,
          //               optimised: 668484.18,
          //             },
          //             {
          //               name: "discovery inc",
          //               previous: 102122.43,
          //               optimised: 209482.13,
          //             },
          //             {
          //               name: "nbc universal inc",
          //               previous: 337469.81,
          //               optimised: 877115.99,
          //             },
          //             {
          //               name: "others",
          //               previous: 147229.48,
          //               optimised: 301286.91,
          //             },
          //             {
          //               name: "the walt disney co",
          //               previous: 34216.3,
          //               optimised: 162058.03,
          //             },
          //             {
          //               name: "turner broadcasting system inc",
          //               previous: 49447.83,
          //               optimised: 98157.72,
          //             },
          //             {
          //               name: "univision television group inc",
          //               previous: 99395.94,
          //               optimised: 267291.3,
          //             },
          //             {
          //               name: "viacom inc",
          //               previous: 218115.35,
          //               optimised: 613540.81,
          //             },
          //           ],
          //         },
          //       ],
          //       levelThreeList: [
          //         {
          //           name: "digital - audio",
          //           value: [
          //             {
          //               name: "others",
          //               previous: 0.0,
          //               optimised: 391.77,
          //             },
          //           ],
          //         },
          //         {
          //           name: "digital - display",
          //           value: [
          //             {
          //               name: "others",
          //               previous: 0.0,
          //               optimised: 22426.95,
          //             },
          //             {
          //               name: "pandora.com",
          //               previous: 0.0,
          //               optimised: 4227.84,
          //             },
          //             {
          //               name: "the trade desk inc",
          //               previous: 132481.74,
          //               optimised: 123182.86,
          //             },
          //           ],
          //         },
          //         {
          //           name: "digital - fep",
          //           value: [
          //             {
          //               name: "hulu",
          //               previous: 6831.65,
          //               optimised: 8954.84,
          //             },
          //             {
          //               name: "others",
          //               previous: 16120.83,
          //               optimised: 16662.56,
          //             },
          //             {
          //               name: "peacock",
          //               previous: 0.0,
          //               optimised: 0.0,
          //             },
          //           ],
          //         },
          //         {
          //           name: "digital - social",
          //           value: [
          //             {
          //               name: "facebook.com",
          //               previous: 25070.43,
          //               optimised: 24898.09,
          //             },
          //             {
          //               name: "others",
          //               previous: 0.0,
          //               optimised: 1509.27,
          //             },
          //             {
          //               name: "pinterest",
          //               previous: 23572.21,
          //               optimised: 26895.99,
          //             },
          //             {
          //               name: "snap inc fk snapchat",
          //               previous: 79032.33,
          //               optimised: 123233.56,
          //             },
          //             {
          //               name: "twitter",
          //               previous: 25529.9,
          //               optimised: 47254.35,
          //             },
          //           ],
          //         },
          //         {
          //           name: "digital - video",
          //           value: [
          //             {
          //               name: "conde nast digital",
          //               previous: 53013.66,
          //               optimised: 105291.15,
          //             },
          //             {
          //               name: "others",
          //               previous: 6757.87,
          //               optimised: 6443.13,
          //             },
          //             {
          //               name: "pandora.com",
          //               previous: 18651.99,
          //               optimised: 31536.88,
          //             },
          //             {
          //               name: "roku",
          //               previous: 83370.19,
          //               optimised: 80204.23,
          //             },
          //             {
          //               name: "studio 71,lp",
          //               previous: 39061.12,
          //               optimised: 39523.16,
          //             },
          //             {
          //               name: "the trade desk inc",
          //               previous: 47924.76,
          //               optimised: 52541.38,
          //             },
          //             {
          //               name: "vevo.com",
          //               previous: 0.0,
          //               optimised: 40486.81,
          //             },
          //             {
          //               name: "youtube.com",
          //               previous: 133598.51,
          //               optimised: 138969.89,
          //             },
          //             {
          //               name: "zefr",
          //               previous: 99815.07,
          //               optimised: 146971.1,
          //             },
          //           ],
          //         },
          //         {
          //           name: "commerce (display)",
          //           value: [
          //             {
          //               name: "amazon.com",
          //               previous: 15953.22,
          //               optimised: 67285.54,
          //             },
          //             {
          //               name: "facebook.com",
          //               previous: 20118.54,
          //               optimised: 42090.61,
          //             },
          //             {
          //               name: "others",
          //               previous: 59621.38,
          //               optimised: 94564.72,
          //             },
          //             {
          //               name: "quotient fka coupons",
          //               previous: 0.0,
          //               optimised: 9554.97,
          //             },
          //             {
          //               name: "walmart.com",
          //               previous: 1349.82,
          //               optimised: 4704.98,
          //             },
          //           ],
          //         },
          //         {
          //           name: "commerce (search)",
          //           value: [
          //             {
          //               name: "amazon.com",
          //               previous: 179769.38,
          //               optimised: 174926.6,
          //             },
          //             {
          //               name: "instacart",
          //               previous: 30756.36,
          //               optimised: 33728.27,
          //             },
          //             {
          //               name: "kroger",
          //               previous: 32343.96,
          //               optimised: 36110.52,
          //             },
          //             {
          //               name: "target",
          //               previous: 13653.2,
          //               optimised: 40261.56,
          //             },
          //             {
          //               name: "walmart.com",
          //               previous: 19006.87,
          //               optimised: 20915.07,
          //             },
          //           ],
          //         },
          //         {
          //           name: "digital search (brand.com)",
          //           value: [
          //             {
          //               name: "bing",
          //               previous: 3922.26,
          //               optimised: 23311.93,
          //             },
          //             {
          //               name: "google ads",
          //               previous: 5652.63,
          //               optimised: 37163.7,
          //             },
          //           ],
          //         },
          //         {
          //           name: "a&e television networks llc",
          //           value: [
          //             {
          //               name: "cable",
          //               previous: 162911.56,
          //               optimised: 668484.18,
          //             },
          //           ],
          //         },
          //         {
          //           name: "discovery inc",
          //           value: [
          //             {
          //               name: "others",
          //               previous: 102122.43,
          //               optimised: 209482.13,
          //             },
          //           ],
          //         },
          //         {
          //           name: "nbc universal inc",
          //           value: [
          //             {
          //               name: "cable",
          //               previous: 89065.47,
          //               optimised: 195438.35,
          //             },
          //             {
          //               name: "early morning",
          //               previous: 59121.14,
          //               optimised: 204653.95,
          //             },
          //             {
          //               name: "others",
          //               previous: 143993.7,
          //               optimised: 190235.21,
          //             },
          //             {
          //               name: "prime",
          //               previous: 45289.51,
          //               optimised: 286788.48,
          //             },
          //           ],
          //         },
          //         {
          //           name: "others",
          //           value: [
          //             {
          //               name: "others",
          //               previous: 147229.48,
          //               optimised: 301286.91,
          //             },
          //           ],
          //         },
          //         {
          //           name: "the walt disney co",
          //           value: [
          //             {
          //               name: "others",
          //               previous: 34216.3,
          //               optimised: 162058.03,
          //             },
          //           ],
          //         },
          //         {
          //           name: "turner broadcasting system inc",
          //           value: [
          //             {
          //               name: "cable",
          //               previous: 49447.83,
          //               optimised: 98157.72,
          //             },
          //           ],
          //         },
          //         {
          //           name: "univision television group inc",
          //           value: [
          //             {
          //               name: "hispanic",
          //               previous: 99395.94,
          //               optimised: 267291.3,
          //             },
          //           ],
          //         },
          //         {
          //           name: "viacom inc",
          //           value: [
          //             {
          //               name: "cable",
          //               previous: 172191.52,
          //               optimised: 358657.21,
          //             },
          //             {
          //               name: "others",
          //               previous: 31694.81,
          //               optimised: 221751.8,
          //             },
          //             {
          //               name: "prime",
          //               previous: 14229.02,
          //               optimised: 33131.8,
          //             },
          //           ],
          //         },
          //       ],
          //     },
          //   ],
          //   predictions: [
          //     {
          //       name: "Previous Budget",
          //       value: 2993290.476241188,
          //     },
          //     {
          //       name: "New Budget",
          //       value: 6759561.1890491415,
          //     },
          //     {
          //       name: "Previous Revenue",
          //       value: 2323888.5723736603,
          //     },
          //     {
          //       name: "New Revenue",
          //       value: 4823641.348728576,
          //     },
          //   ],
          // };

         var res1 = this.budgetDataOptimized
         var res;
          if (
            sessionStorage.getItem("edited") === "edited" &&
            this.$route.params.response !== undefined
          ) {
            res = this.$route.params.response;
          } else {
            res = res1;
          }
          this.saveOptimizedResult.runId = sessionStorage.getItem("runId");
          // this.saveOptimizedResult.values.growth = res.response.result.growth;
          // this.saveOptimizedResult.values.kpiSelection =
          //   res.response.result.kpiSelection;
          this.saveOptimizedResult.values.mediaData = res[0].redistribution;
          this.saveOptimizedResult.values.optimised_output = res[0].optimised_output;
          this.saveOptimizedResult.values.target = sessionStorage.getItem(
            "targetVariable"
          );
          this.predictionData = [];
          // console.log(res.response.result.kpiSelection.previous)
          if (sessionStorage.getItem("targetVariable") === "KPI") {
            this.predictionData.push({
              name: "Previous Budget " + this.kpiMarket,
              value: res.predictions[0].value,
              image: "Last year Revenue",
            });
            this.predictionData.push({
              name: "Expected " + this.kpiMarket,
              value: res.result.kpiSelection.expected,
              image: "Expected Revenue",
            });
          } else {
            this.predictionData.push({
              name: "Previous Budget",
              value: res[0].values.predictions[0].value,
              image: "Last year Revenue",
            });
            this.predictionData.push({
              name: "Optimised Budget",
              value: res[0].values.predictions[1].value,
              image: "Predicted Budget",
            });
            this.predictionData.push({
              name: "Predicted MIR",
              value: res[0].values.predictions[2].value,
              image: "Expected Revenue",
            });
            this.predictionData.push({
              name: "Expected MIR",
              value: res[0].values.predictions[3].value,
              image: "Expected Revenue",
            });
              this.predictionData.push({
              name: "Change in Revenue",
              value: res[0].values.predictions[4].value,
              image: "Expected Revenue",
              symbol: "%"
            });
              this.predictionData.push({
              name: "Change in Budget",
              value: res[0].values.predictions[5].value,
              image: "Expected Revenue",
                            symbol: "%"

            });
            this.predictionData.push({
              name: "Previous ROI",
              value: res[0].values.predictions[6].value,
              image: "Last year Revenue",
            });
              this.predictionData.push({
              name: "Expected ROI",
              value: res[0].values.predictions[7].value,
              image: "Predicted Budget",
            });
          }
          // this.predictionData.push({
          //   name: "Change",
          //   value:
          //     this.orgnizeNumber(res.response.result.kpiSelection.variance) +
          //     "%",
          //   image: "Variance",
          // });
          // this.predictionData.push({
          //   name: "Previous Budget",
          //   value: res.response.result.optimised_output.previous,
          //   image: "Previous Budget",
          // });
          // this.predictionData.push({
          //   name: "Optimized Budget",
          //   value: res.response.result.optimised_output.optimized,
          //   image: "Predicted Budget",
          // });
          // this.predictionData.push({
          //   name: "Change",
          //   value:
          //     this.orgnizeNumber(
          //       res.response.result.optimised_output.variance
          //     ) + "%",
          //   image: "Variance (2)",
          // });
          this.optimizedData = [];
          for (var i = 0; i < res[0].redistribution.length; i++) {
            var total = 0;
            for (var k = 0; k < res[0].redistribution[i].levelOne.length; k++) {
              total += res[0].redistribution[i].levelOne[k].optimised;
            }
            var stripData = [];
            var reaarrangeData = this.rearrangeArray(
              res[0].redistribution[i].levelOne,
              res[0].redistribution[i].levelOne.length
            );
            for (var j = 0; j < reaarrangeData.length; j++) {
              var per = Math.round((reaarrangeData[j].optimised * 100) / total);
              if (per < 5) {
                per = 5;
              }
              stripData.push({
                label: reaarrangeData[j].name,
                value: this.orgnizeNumber(reaarrangeData[j].optimised),
                actualValue: reaarrangeData[j].optimised,
                backgroundColor: color[j],
                percentage: per,
                previous: reaarrangeData[j].previous,
              });
              if (j === res[0].redistribution[i].levelOne.length - 1) {
                if (sessionStorage.getItem("targetVariable") === "KPI") {
                  this.optimizedData.push({
                    title: res[0].redistribution[i].title,
                    stripData: stripData,
                    expandData: {
                      data: [
                        {
                          name: "Previous " + this.kpiMarket,
                          data: [],
                        },
                        {
                          name: "Optimized " + this.kpiMarket,
                          data: [],
                        },
                      ],
                      xAxis: {
                        categories: [],
                        crosshair: true,
                      },
                      yAxis: {
                        min: 0,
                        title: {
                          text: "Optimized " + this.kpiMarket,
                        },
                      },
                      plotOptions: {
                        column: {
                          pointPadding: 0.4,
                          borderWidth: 0,
                        },
                        series: {
                          borderRadiusTopLeft: 25,
                          borderRadiusTopRight: 25,
                        },
                      },
                      subtitle: {},
                      legend: {},
                      color: ["#40c4ff", "#536dfe"],
                      tooltip: {
                        headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                        pointFormat:
                          '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                          '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                        footerFormat: "</table>",
                        shared: true,
                        useHTML: true,
                        shadow: false,
                        style: {
                          padding: 0,
                        },
                      },
                    },
                    expandLevelTwoData: {
                      data: [
                        {
                          name: "Previous Budget",
                          data: [],
                        },
                        {
                          name: "Optimized Budget",
                          data: [],
                        },
                      ],
                      xAxis: {
                        categories: [],
                        crosshair: true,
                      },
                      yAxis: {
                        min: 0,
                        title: {
                          text: "Optimized Budget",
                        },
                      },
                      plotOptions: {
                        column: {
                          pointPadding: 0.4,
                          borderWidth: 0,
                        },
                        series: {
                          borderRadiusTopLeft: 25,
                          borderRadiusTopRight: 25,
                        },
                      },
                      subtitle: {},
                      legend: {},
                      color: ["#85edff", "#b5a9ff"],
                      tooltip: {
                        headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                        pointFormat:
                          '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                          '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                        footerFormat: "</table>",
                        shared: true,
                        useHTML: true,
                        shadow: false,
                        style: {
                          padding: 0,
                        },
                      },
                    },
                    breakDown: false,
                    allExpandData: res[0].redistribution[i].levelTwo,
                  });
                } else {
                  this.optimizedData.push({
                    title: res[0].redistribution[i].title,
                    stripData: stripData,
                    expandData: {
                      data: [
                        {
                          name: "Previous Budget",
                          data: [],
                        },
                        {
                          name: "Optimized Budget",
                          data: [],
                        },
                      ],
                      xAxis: {
                        categories: [],
                        crosshair: true,
                      },
                      yAxis: {
                        min: 0,
                        title: {
                          text: "Optimized Budget",
                        },
                      },
                      plotOptions: {
                        column: {
                          pointPadding: 0.4,
                          borderWidth: 0,
                        },
                        series: {
                          borderRadiusTopLeft: 25,
                          borderRadiusTopRight: 25,
                        },
                      },
                      subtitle: {},
                      legend: {},
                      color: ["#40c4ff", "#536dfe"],
                      tooltip: {
                        headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                        pointFormat:
                          '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                          '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                        footerFormat: "</table>",
                        shared: true,
                        useHTML: true,
                        shadow: false,
                        style: {
                          padding: 0,
                        },
                      },
                    },
                    expandLevelTwoData: {
                      data: [
                        {
                          name: "Previous Budget",
                          data: [],
                        },
                        {
                          name: "Optimized Budget",
                          data: [],
                        },
                      ],
                      xAxis: {
                        categories: [],
                        crosshair: true,
                      },
                      yAxis: {
                        min: 0,
                        title: {
                          text: "Optimized Budget",
                        },
                      },
                      plotOptions: {
                        column: {
                          pointPadding: 0.4,
                          borderWidth: 0,
                        },
                        series: {
                          borderRadiusTopLeft: 25,
                          borderRadiusTopRight: 25,
                        },
                      },
                      subtitle: {},
                      legend: {},
                      color: ["#85edff", "#b5a9ff"],
                      tooltip: {
                        headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                        pointFormat:
                          '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                          '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                        footerFormat: "</table>",
                        shared: true,
                        useHTML: true,
                        shadow: false,
                        style: {
                          padding: 0,
                        },
                      },
                    },
                    breakDown: false,
                    allExpandData: res[0].redistribution[i].levelTwo,
                  });
                }
              }
            }
            this.$refs.contentBlock[i].updateBreakdown(false);
          }
          // this.revenueData = [
          //   {
          //     name: "Expected Revenue",
          //     value: this.orgnizeNumber(res.kpiSelection.expected),
          //   },
          // ];
          // this.kpiData = [
          //   {
          //     name: "Target " + this.kpiMarket,
          //     value: this.orgnizeNumber(
          //       res.response.kpiSelection.expected
          //     ),
          //   },
          // ];
        });
    },
    getExpandData(e, index) {
      this.optimizedData[index].expandData.data[0].data = [];
      this.optimizedData[index].expandData.data[1].data = [];
      this.optimizedData[index].expandData.xAxis.categories = [];
      this.optimizedData[index].breakDown = true;
      var expandDataObj = this.optimizedData[index].allExpandData.find(
        (obj) => obj.name.toLowerCase() === e.label.toLowerCase()
      ).value;
      if (expandDataObj !== undefined) {
        for (var i = 0; i < expandDataObj.length; i++) {
          this.optimizedData[index].expandData.data[0].data.push(
            expandDataObj[i].previous
          );
          this.optimizedData[index].expandData.data[1].data.push(
            expandDataObj[i].optimised
          );
          this.optimizedData[index].expandData.xAxis.categories.push(
            expandDataObj[i].name
          );
        }
      }
    },
  },
  created() {
    this.sliderRevenueValue = parseInt(sessionStorage.getItem("actualGrowth"));
    this.kpiMarket = sessionStorage.getItem("kpiConfig");
    this.sliderKPIValue = this.sliderRevenueValue;
    if (sessionStorage.getItem("targetVariable") === "budget") {
      this.activePredictionTab = "Budget Based";
    } else if (sessionStorage.getItem("targetVariable") === "KPI") {
      this.activePredictionTab = this.kpiMarket + " Based";
    }
    this.getAIPlanningResult();
  },
  computed: {
    predictionTab() {
      return [
        {
          category: "Budget Based",
        },
        {
          category: this.kpiMarket + " Based",
        },
      ];
    },
    orgnizeNumber() {
      return (data) => {
        if (data > 999) {
          return abbreviateNumber(data, 1);
        } else if (data < 0) {
          var posNumber = Math.abs(data);
          return "-" + abbreviateNumber(posNumber, 1);
        } else {
          return Math.round(data * 10) / 10;
        }
      };
    },
  },
};
</script>
<style>
.slide-parent .rs-handle.rs-move {
  border: 11px solid #fff;
}
</style>

<style scoped>
.card {
  box-shadow: 0 0 8px 0 #c9ced5;
}
.card-title {
  margin: 24px 0px 0px 24px;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}

.arrow-card {
  width: 74px;
  height: 74px;
  padding: 25px;
  box-shadow: 0 0 8px 0 #c9ced5;
}

.arrow-card-collapse {
  background-color: black;
  left: 0;
  margin-top: 0px;
}

.arrow {
  font-size: 18px;
}

.arrow-white {
  color: #fff;
  font-size: 18px;
}

.btn-group {
  margin-bottom: 31px;
}

.btn-effect-outline {
  padding: 6px;
  border-radius: 5px;
  border: solid 2px #050505;
  font-family: ProximaNovaBold;
  font-size: medium;
  color: #222a37 !important;
  background-color: transparent !important;
}
.btn-effect-outline:hover {
  color: #fff !important;
}
.wrapper-statistics {
  padding: 40px 24px;
}

.item {
  display: flex;
  margin: 20px 0px;
  align-items: center;
}

/* .item:not(:nth-child(3n)):before {
  content: "";
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
  top: 8px;
} */

.item-right-line {
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
}

.item-img {
  background: #67e8ff;
  border-radius: 50%;
  padding: 2rem;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.item-text__title {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8394b4;
  padding-bottom: 1rem;
}

.item-text__subtitle {
  font-family: ProximaNovaBold;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
}

.blue-pastel {
  background: #ff8a80;
}
.green-pastel {
  background: #ff80ab;
}

.pink-pastel {
  background: #64ffda;
}

.red-pastel {
  background: #ffd740;
}

.yellow-pastel {
  background: #40c4ff;
}
.purple-pastel {
  background: #ea80fc;
}

.Additional-details {
  width: 259px;
  margin: 1px 986px 2px 0;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
  padding: 28px 24px;
}

.details-heading {
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}

.form-check-input {
  height: 24px;
  position: relative;
  top: 0px;
  margin: 0px;
  margin-right: 10px;
}

.form-check-input:checked {
  background-color: #222a37;
  border-color: #222a37;
}
label {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #9aafd4;
  margin-top: 37px;
}
input[type="number"] {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
  border-radius: 0;
  background-color: #eff5ff;
}

input[type="date"] {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
  border-radius: 0;
  width: 100%;
  background-color: #eff5ff;
}
select {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
  border-radius: 0;
  padding-left: 0;
  background-color: #eff5ff;
}
option {
  color: #222a37;
  font-family: ProximaNovaRegular;
  font-size: 16px;
}
select:focus {
  border: none;
  border-bottom: 1px solid black;
  box-shadow: none;
  outline: none;
}
.detail-bg {
  background-color: #eff5ff;
}
</style>
